import React, { useEffect, useState } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

import { BASE_URL } from "./../urlConfig";
import authHeader from "./../utils/auth-header";

import { Upload, Modal, message } from "antd";

const Avatar = ({ name, label, form, fileList, onChange, disabled }) => {
  const [picture, setPicture] = useState(null);

  const [loadingPic, setLoadingPic] = useState(false);

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  useEffect(() => {
    if (fileList && fileList[0] && fileList[0].url) {
      setPicture(fileList);
    }
  }, [fileList]);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleRemove = (info) => {
    setPicture(null);
    onChange({
      type: "success",
      fileReturn: "",
      fileListReturn: [],
    });
  };

  const handleChange = (info) => {
    onChange(info);
    if (info.file.status === "uploading") {
      setLoadingPic(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setLoadingPic(false);
        setPicture([{ url: imageUrl }]);
      });
    }
  };

  const handlePreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    setPreviewImage(src);
    setPreviewVisible(true);
  };

  const uploadButton = (
    <div>
      {loadingPic ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Fichier</div>
    </div>
  );

  const beforeUpload = file => {
    if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
      message.error(`${file.name} n'est pas autorisé`);
    }
    return file.type === 'image/png' || file.type === 'image/jpeg' ? true : Upload.LIST_IGNORE;
  }

  return (
    <>
      <Upload
        locale={{
          uploading: "Téléversement...",
          removeFile: "Supprimer le fichier",
          uploadError: "Erreur",
          previewFile: "Voir le fichier",
          downloadFile: "Télécharger",
        }}
        name="avatar"
        listType="picture-card"
        fileList={fileList}
        multiple={false}
        maxCount={1}
        disabled={disabled}
        action={`${BASE_URL}/users/upload`}
        headers={authHeader()}
        onChange={handleChange}
        onRemove={handleRemove}
        onPreview={handlePreview}
        beforeUpload={beforeUpload} 
      >
        {picture ? null : uploadButton}
      </Upload>
      <Modal
        visible={previewVisible}
        title="&nbsp;"
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};
export default Avatar;
