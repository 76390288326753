import React, { useEffect } from "react";
import { observer, inject } from "mobx-react";
import { Table, Tag, Button, Popconfirm, PageHeader } from "antd";
import useTableSearch from "../../components/useTableSearch";
import useTableDate from "../../components/useTableDate";
import { Link } from "react-router-dom";
import frFR from "rc-pagination/lib/locale/fr_FR";

import {
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";

const Permissions = ({ permissionStore }) => {
  useEffect(() => {
    permissionStore.getAll();
  }, [permissionStore]);

  const dataSource = permissionStore.records;
  const getColumnDateProps = useTableDate();
  const getColumnSearchProps = useTableSearch();

  const columns = [
    {
      title: "Id",
      width: 10,
      dataIndex: "id",
      key: "id",
      sorter: true,
      sortOrder:
        permissionStore.sortField === "id" && permissionStore.sortOrder,
      defaultSortOrder: "ascend",
      ...getColumnSearchProps("Id"),
      defaultFilteredValue:
        permissionStore.filters && permissionStore.filters.id,
    },
    {
      title: "Roles",
      key: "roles.name",
      dataIndex: "roles",
      sorter: true,
      sortOrder:
        permissionStore.sortField === "roles" && permissionStore.sortOrder,
      render: (roles) => (
        <>
          {roles.map((role) => {
            return <Tag key={role.id}>{role.name}</Tag>;
          })}
        </>
      ),
      filters: [
        { text: "Superadmin", value: "superadmin" },
        { text: "Admin", value: "admin" },
        { text: "Guest", value: "guest" },
      ],
      defaultFilteredValue:
        permissionStore.filters && permissionStore.filters["roles.name"],
    },
    {
      title: "Resource",
      dataIndex: "resource",
      key: "resource",
      sorter: true,
      sortOrder:
        permissionStore.sortField === "resource" && permissionStore.sortOrder,
      ...getColumnSearchProps("Resource"),
      defaultFilteredValue:
        permissionStore.filters && permissionStore.filters.resource,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      sorter: true,
      sortOrder:
        permissionStore.sortField === "action" && permissionStore.sortOrder,
      ...getColumnSearchProps("Action"),
      defaultFilteredValue:
        permissionStore.filters && permissionStore.filters.action,
    },
    {
      title: "Atttributs",
      dataIndex: "attributes",
      key: "attributes",
      sorter: true,
      sortOrder:
        permissionStore.sortField === "attributes" && permissionStore.sortOrder,
      ...getColumnSearchProps("Atttributs"),
      defaultFilteredValue:
        permissionStore.filters && permissionStore.filters.attributes,
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      sortOrder:
        permissionStore.sortField === "createdAt" && permissionStore.sortOrder,
      ...getColumnDateProps("Date de création"),
      defaultFilteredValue:
        permissionStore.filters && permissionStore.filters.createdAt,
    },
    {
      title: "Actions",
      width: 150,
      key: "action",
      align: "left",
      render: (text, record) => (
        <>
          <Link to={`/admin/users/permissions/detail/${record.id}`}>
            <Button
              type="default"
              shape="circle"
              icon={<EyeOutlined />}
              style={{ marginRight: 5 }}
            ></Button>
          </Link>
          <Link to={`/admin/users/permissions/edit/${record.id}`}>
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              style={{ marginRight: 5 }}
            ></Button>
          </Link>

          <Popconfirm
            title="Supprimer ?"
            okText="Oui"
            cancelText="Annuler"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={async () => {
              await permissionStore.deleteRecord(record.id);
              permissionStore.getAll();
            }}
          >
            <Button
              type="danger"
              shape="circle"
              icon={<DeleteOutlined />}
            ></Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    permissionStore.getAll({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters,
    });
  };

  return (
    <div>
      <PageHeader
        style={{ padding: "10px 0" }}
        title="Permissions"
        subTitle="Liste"
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 20,
        }}
      >
        <Link to={`/admin/users/permissions/add`}>
          <Button type="primary" icon={<PlusOutlined />}>
            Ajouter
          </Button>
        </Link>
      </div>
      <Table
        onChange={handleTableChange}
        locale={{
          emptyText: "Aucuns enregistrements",
        }}
        pagination={{
          ...permissionStore.pagination,
          showTotal: (total, range) => `${range[0]}-${range[1]} / ${total}`,
          showPrevNextJumpers: false,
          locale: frFR,
        }}
        rowKey={(record) => record.id}
        loading={permissionStore.loading}
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );
};

export default inject("permissionStore")(observer(Permissions));
