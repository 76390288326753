import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { observer, inject } from "mobx-react";
var _ = require("lodash");

const ProtectedRoute = ({ component: Component, chidren, layout: Layout, props, LoginStore, ...rest }) => {
    //if (localStorage.getItem('isAuthenticated') && LoginStore.roles.length) {
    if (localStorage.getItem('isAuthenticated')) {
        // console.log(LoginStore.roles);
        if (LoginStore.roles.length) {
            if (_.find(LoginStore.roles, { resource: rest.name, action: "*" })) {
                return (
                    <Route
                        {...rest}
                        render={({ location }) =>
                            <Layout >
                                <Component />
                            </Layout>
                        }
                    />
                );
            } else {
                return (
                    <Route
                        {...rest}
                        render={({ location }) =>
                            <Redirect
                                to={{
                                    pathname: "/unauth",
                                    state: { from: location }
                                }}
                            />
                        }
                    />
                )

            }
        } else {
            return <></>
        }
    } else {
        return (
            //     <Route
            //         {...rest}
            //         render={({ location }) =>
            <Redirect
                to={{
                    pathname: "/login"
                }}
            />
            //         }
            //     />
        )
        // return <></>
    }
};
export default inject("LoginStore")(observer(ProtectedRoute));
