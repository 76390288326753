import { observable, action } from 'mobx';
import axios from 'axios';
import { BASE_URL } from '../urlConfig'
import setCommonStore from './setCommon'


class MemberStore {
    @observable loading = false;
    @observable success = false;
    @observable error = false;

    @action
    getAllUser = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/user/get`, {
                headers: {
                    "x-access-token": `${localStorage.getItem('token')}`,
                },

            })

            if (res) {
                // console.log(res.data.users)
                setCommonStore.setUsers(res.data.users);

            }

        } catch (e) {
            this.error = 'Oops , Something is wrong !!!'

        }

    }
}


export default new MemberStore()