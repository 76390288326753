import { Button, Space, DatePicker } from "antd";
import React from "react";
import { SearchOutlined, FilterFilled } from "@ant-design/icons";
import moment from "moment";
import "moment/locale/fr";
import locale from "antd/es/date-picker/locale/fr_FR";
const { RangePicker } = DatePicker;

const useTableDate = () => {
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <div>
          <RangePicker
            locale={locale}
            defaultValue={
              selectedKeys.length > 0
                ? [
                    moment(selectedKeys[0], "DD/MM/YYYY"),
                    moment(selectedKeys[1], "DD/MM/YYYY"),
                  ]
                : null
            }
            format="DD/MM/YYYY"
            onChange={(date, dateString) => {
              console.log(date, dateString);
              setSelectedKeys(dateString);
            }}
            style={{ width: 260, marginBottom: 8 }}
          />
        </div>
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Filtrer
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Supprimer
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <FilterFilled />,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => searchDate.focus(), 100);
      }
    },
    render: (text) => moment(text).format("DD-MM-YYYY HH:mm:ss"),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  return getColumnSearchProps;
};

export default useTableDate;
