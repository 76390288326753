import { observable, action, makeAutoObservable } from 'mobx';
import axios from 'axios';
import { BASE_URL } from '../urlConfig'
// import setCommonStore from './setCommon'
import { notification } from 'antd';
import ApiService from "./../utils/api";
// import authHeader from './../utils/auth-header';


class mealStore {
    @observable loading = false;
    @observable records = [];
    @observable pagination = {
        current: 1,
        pageSize: 10,
    }
    @observable filters = null
    @observable sortField = null;
    @observable sortOrder = null;

    constructor() {
        makeAutoObservable(this)
    }

    @action
    setRecords(values) {
        this.records = values;
    }

    @action
    setFilters(values) {
        this.filters = values;
    }

    @action
    setLoading(val) {
        this.loading = val;
    }

    @action
    setSortField(val) {
        this.sortField = val;
    }
    @action
    setSortOrder(val) {
        this.sortOrder = val;
    }

    @action
    setPagination(val) {
        this.pagination = val;
    }

    @action
    deleteRecord = async (id = null) => {
        this.setLoading(true)
        try {
            await ApiService.delete(`/meals/${id}`)
            this.setLoading(false)
            notification.success({ message: "Enregistrement supprimé" })
        } catch (error) {
            this.setLoading(false)
            // notification.error({ message: error.message })
        }
    }

    @action
    uploadAudio = async (binary = null) => {
        console.log(binary)
        try {
            const token = localStorage.getItem('token');
            var formData = new FormData();
            formData.append("file", binary);
            const res = await axios.post(`${BASE_URL}/meals/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": `Bearer ${token}`
                }
            });
            return res;
        } catch (error) {
            notification.error({ message: error.message })
        }
    }

    @action
    getAll = async (params = { pagination: this.pagination, sortField: this.sortField, sortOrder: this.sortOrder, filters: this.filters }) => {
        this.setSortField(params.sortField)
        this.setSortOrder(params.sortOrder)
        this.setFilters(params.filters);
        this.setLoading(true)
        try {
            const res = await ApiService.get('/meals', params)
            // console.log(res)
            if (res) {
                this.setPagination({
                    ...params.pagination,
                    total: res.count,
                })
                this.setRecords(res.rows);
            }
            this.setLoading(false)
        } catch (error) {
            this.setLoading(false)
            // notification.error({ message: error.message })
        }
    }
}


export default new mealStore()