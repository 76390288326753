
import { useEffect, useRef, useState } from "react";

// Recorder UI component
export default function AudioUI({
  time,
  stop,
  data,
  start,
  pause,
  reset,
  resume,
  paused,
  recording
}) {
  const audioRef = useRef(null);
  // const audioRef = useRef<HTMLAudioElement>(null);
  const [hasRecording, setHasRecording] = useState(false);
  const [file, setFile] = useState(false);

  const togglePlay = () => {
    if (audioRef.current?.paused) {
      audioRef.current?.play();
    } else {
      audioRef.current?.pause();
    }
  };

  const deleteSound = () => {
    audioRef.current.src = null;
    reset()
    setFile(false);
  }

  useEffect(() => {
    if (data && data.url && audioRef.current) {
      console.log(data);
      audioRef.current.src = data.url;
      setFile(true);
    }
  }, [data]);

  return (
    <>
      {!file && (
        <button
            type="button"
            onClick={() => {
              if (recording) {
                stop();
                setHasRecording(true);
              } else {
                start();
                setHasRecording(false);
              }
            }}
            style={{ margin: "10px" }}
        >
          {recording ? "Stop" : "Start"}
        </button>
      )}
      

      {recording && (
        <>
          <button
            type="button"
            onClick={() => {
              if (recording) {
                if (paused) resume();
                else pause();
              }
            }}
            style={{ margin: "10px" }}
          >
            {paused ? "Resume" : "Pause"}
          </button>
          <p>
            {time.h}:{time.m}:{time.s}
          </p>
        </>
      )}

      {!recording && hasRecording && (
        <>
          <br />
          <br />
          <button type="button" onClick={togglePlay} style={{ margin: "10px" }}>
            Play/Pause
          </button>
          <button type="button" onClick={deleteSound} style={{ margin: "10px" }}>
            Reset
          </button>
        </>
      )}

      <audio ref={audioRef} hidden />
    </>
  );
}
