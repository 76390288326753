import React, { useEffect, useState } from "react";
import { Modal, Form, Select } from "antd";
var _ = require("lodash");

const PasswordGenerator = ({ visible, onCreate, onCancel }) => {
  const [form_generator] = Form.useForm();

  const animaux = [
    {
      name: "Cerf",
      value: "cerf",
      disabled: false,
    },
    {
      name: "Lapin",
      value: "lapin",
      disabled: false,
    },
    {
      name: "Loup",
      value: "loup",
      disabled: false,
    },
    {
      name: "Chat",
      value: "chat",
      disabled: false,
    },
    {
      name: "Chien",
      value: "chien",
      disabled: false,
    },
    {
      name: "Pingouin",
      value: "pingouin",
      disabled: false,
    },
  ];

  useEffect(()=>{
    if(visible){
      console.log("ici")
      console.log(form_generator.getFieldsValue())
      form_generator.resetFields()
      setList1(animaux);
      setList2(animaux);
      setList3(animaux);
    }
  }, [visible, form_generator])

  const [list1, setList1] = useState(animaux);
  const [list2, setList2] = useState(animaux);
  const [list3, setList3] = useState(animaux);

  function handleChange(value, list) {
    // console.log(value, list);
    // console.log(form.getFieldsValue())
    const currentValues = _.values(form_generator.getFieldsValue());
    console.log(currentValues);
    // console.log(_.includes(currentValues, 'chien'))

    switch (list) {
      case "list1":
        setList2(
          list2.map((elt) => {
            if (_.includes(currentValues, elt.value)) {
              elt.disabled = true;
            } else {
              elt.disabled = false;
            }
            return elt;
          })
        );
        setList3(
          list3.map((elt) => {
            if (_.includes(currentValues, elt.value)) {
              elt.disabled = true;
            } else {
              elt.disabled = false;
            }
            return elt;
          })
        );
        break;
      case "list2":
        setList1(
          list1.map((elt) => {
            if (_.includes(currentValues, elt.value)) {
              elt.disabled = true;
            } else {
              elt.disabled = false;
            }
            return elt;
          })
        );
        setList3(
          list3.map((elt) => {
            if (_.includes(currentValues, elt.value)) {
              elt.disabled = true;
            } else {
              elt.disabled = false;
            }
            return elt;
          })
        );
        break;
      case "list3":
        setList1(
          list1.map((elt) => {
            if (_.includes(currentValues, elt.value)) {
              elt.disabled = true;
            } else {
              elt.disabled = false;
            }
            return elt;
          })
        );
        setList2(
          list2.map((elt) => {
            if (_.includes(currentValues, elt.value)) {
              elt.disabled = true;
            } else {
              elt.disabled = false;
            }
            return elt;
          })
        );
        break;
      default:
        break;
    }
  }

  return (
    <Modal
      visible={visible}
      title='Générer un mot de passe "simple"'
      okText="Générer"
      cancelText="Annuler"
      onCancel={onCancel}
      onOk={() => {
        form_generator
          .validateFields()
          .then((values) => {
            form_generator.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form_generator}
        layout="vertical"
        name="form_generator"
        initialValues={{}}
      >
        <Form.Item label="Animal 1" name="list1" rules={[{ required: true,message: "Champ obligatoire" }]}>
          <Select onChange={(value) => handleChange(value, "list1")}>
            {list1.map((animal, i) => (
              <Select.Option
                value={animal.value}
                key={animal.value}
                disabled={animal.disabled}
              >
                {animal.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Animal 2" name="list2" rules={[{ required: true,message: "Champ obligatoire" }]}>
          <Select onChange={(value) => handleChange(value, "list2")}>
            {list2.map((animal, i) => (
              <Select.Option
                value={animal.value}
                key={animal.value}
                disabled={animal.disabled}
              >
                {animal.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Animal 3" name="list3" rules={[{ required: true,message: "Champ obligatoire" }]}>
          <Select onChange={(value) => handleChange(value, "list3")}>
            {list3.map((animal, i) => (
              <Select.Option
                value={animal.value}
                key={animal.value}
                disabled={animal.disabled}
              >
                {animal.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PasswordGenerator;
