import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";
import { Table, Button, Popconfirm, PageHeader, Tag } from "antd";
import useTableSearch from "../../components/useTableSearch";
// import useTableDate from "../../components/useTableDate";
import useTableSimpleDate from "../../components/useTableSimpleDate";
import { Link } from "react-router-dom";
import frFR from "rc-pagination/lib/locale/fr_FR";

import {
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  PlusOutlined,
  SoundOutlined,
} from "@ant-design/icons";

const Meals = ({ mealStore, userStore }) => {
  useEffect(() => {
    async function fetchData() {
      mealStore.getAll();
      await userStore.getAll();
      let outTest = [];
      userStore.records.forEach((element) => {
        // console.log(element);
        outTest.push({
          //value: element.id,
          value: element.id,
          text: element.name,
        });
      });
      setUsers(outTest);
    }
    fetchData();
  }, [mealStore, userStore]);

  const [users, setUsers] = useState([]);

  const dataSource = mealStore.records;
  // const getColumnDateProps = useTableDate();
  const getColumnSimpleDateProps = useTableSimpleDate();
  const getColumnSearchProps = useTableSearch();

  const columns = [
    {
      title: "Id",
      width: 10,
      dataIndex: "id",
      key: "id",
      sorter: true,
      sortOrder: mealStore.sortField === "id" && mealStore.sortOrder,
      defaultSortOrder: "ascend",
      ...getColumnSearchProps("Id"),
      defaultFilteredValue: mealStore.filters && mealStore.filters.id,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: true,
      sortOrder: mealStore.sortField === "date" && mealStore.sortOrder,
      ...getColumnSimpleDateProps("Date du repas"),
      defaultFilteredValue:
      mealStore.filters && mealStore.filters.date,
    },
    {
      title: "Période",
      key: "period",
      dataIndex: "period",
      sorter: true,
      sortOrder: mealStore.sortField === "period" && mealStore.sortOrder,
      render: (value) => (<>{value === 'am' ? 'Midi' : 'Soir'}</>),
      filters: [
        { text: "Midi", value: "am" },
        { text: "Soir", value: "pm" },
      ],
      defaultFilteredValue:
      mealStore.filters && mealStore.filters["period"],
    },
    {
      title: "Entrée",
      dataIndex: "starter",
      key: "starter",
      sorter: true,
      sortOrder: mealStore.sortField === "starter" && mealStore.sortOrder,
      ...getColumnSearchProps("Entrée"),
      defaultFilteredValue: mealStore.filters && mealStore.filters.starter,
    },
    {
      title: "Plat",
      dataIndex: "main",
      key: "main",
      sorter: true,
      sortOrder: mealStore.sortField === "main" && mealStore.sortOrder,
      ...getColumnSearchProps("Plat"),
      defaultFilteredValue: mealStore.filters && mealStore.filters.main,
    },
    {
      title: "Dessert",
      dataIndex: "dessert",
      key: "dessert",
      sorter: true,
      sortOrder: mealStore.sortField === "dessert" && mealStore.sortOrder,
      ...getColumnSearchProps("Dessert"),
      defaultFilteredValue: mealStore.filters && mealStore.filters.dessert,
    },
    {
      title: "Audio",
      dataIndex: "audio",
      key: "audio",
      sorter: true,
      sortOrder: mealStore.sortField === "audio" && mealStore.sortOrder,
      render: (value) => (<>{value && value !== '' && value !== undefined ? <SoundOutlined /> : ''}</>),
      filters: [
        { text: "Présent", value: "all" },
        { text: "Absent", value: "empty" },
      ],
      defaultFilteredValue:
      mealStore.filters && mealStore.filters["audio"],
    },
    {
      title: "Utilisateurs",
      key: "users.id",
      dataIndex: "users",
      sorter: true,
      sortOrder: mealStore.sortField === "users" && mealStore.sortOrder,
      render: (users) => (
        <>
          {users.map((user) => {
                return (
                  user.UsersMeals && user.UsersMeals.presence === true && <div key={user.id}>
                    <Tag>
                      <strong>{user.name} {user.presence}</strong>
                    </Tag>
                  </div>
                );
          })}
        </>
      ),
      filters: users,
      defaultFilteredValue:
        mealStore.filters && mealStore.filters["users.id"],
    },
    {
      title: "Actions",
      width: 150,
      key: "action",
      align: "left",
      render: (text, record) => (
        <>
          <Link to={`/admin/meals/detail/${record.id}`}>
            <Button
              type="default"
              shape="circle"
              icon={<EyeOutlined />}
              style={{ marginRight: 5 }}
            ></Button>
          </Link>
          <Link to={`/admin/meals/edit/${record.id}`}>
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              style={{ marginRight: 5 }}
            ></Button>
          </Link>

          <Popconfirm
            title="Supprimer ?"
            okText="Oui"
            cancelText="Annuler"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={async () => {
              await mealStore.deleteRecord(record.id);
              mealStore.getAll();
            }}
          >
            <Button
              type="danger"
              shape="circle"
              icon={<DeleteOutlined />}
            ></Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    mealStore.getAll({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters,
    });
  };

  return (
    <div>
      <PageHeader
        style={{ padding: "10px 0" }}
        title="Repas"
        subTitle="Liste"
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 20,
        }}
      >
        <Link to={`/admin/meals/add`}>
          <Button type="primary" icon={<PlusOutlined />}>
            Ajouter
          </Button>
        </Link>
      </div>
      <Table
        onChange={handleTableChange}
        locale={{
          emptyText: "Aucuns enregistrements",
        }}
        pagination={{
          ...mealStore.pagination,
          showTotal: (total, range) => `${range[0]}-${range[1]} / ${total}`,
          showPrevNextJumpers: false,
          locale: frFR,
        }}
        rowKey={(record) => record.id}
        loading={mealStore.loading}
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );
};

export default inject("mealStore","userStore")(observer(Meals));
