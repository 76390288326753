import React, { useEffect } from "react";
import { observer, inject } from "mobx-react";
import { Table, Button, Popconfirm, PageHeader, Tag } from "antd";
import useTableSearch from "../../components/useTableSearch";
import useTableDate from "../../components/useTableDate";
import { Link } from "react-router-dom";
import frFR from "rc-pagination/lib/locale/fr_FR";

import {
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";

const Roles = ({ roleStore }) => {
  useEffect(() => {
    roleStore.getAll();
  }, [roleStore]);

  const dataSource = roleStore.records;
  const getColumnDateProps = useTableDate();
  const getColumnSearchProps = useTableSearch();

  const columns = [
    {
      title: "Id",
      width: 10,
      dataIndex: "id",
      key: "id",
      sorter: true,
      sortOrder: roleStore.sortField === "id" && roleStore.sortOrder,
      defaultSortOrder: "ascend",
      ...getColumnSearchProps("Id"),
      defaultFilteredValue: roleStore.filters && roleStore.filters.id,
    },
    {
      title: "Nom",
      dataIndex: "name",
      key: "name",
      sorter: true,
      sortOrder: roleStore.sortField === "name" && roleStore.sortOrder,
      ...getColumnSearchProps("Nom"),
      defaultFilteredValue: roleStore.filters && roleStore.filters.name,
    },
    {
      title: "Permissions",
      key: "permissions.resource",
      dataIndex: "permissions",
      sorter: true,
      sortOrder: roleStore.sortField === "permissions" && roleStore.sortOrder,
      render: (permissions) => (
        <>
          {permissions.map((permission) => {
            return (
              <div key={permission.id}>
                <Tag>
                  <strong>{permission.resource} :</strong> {permission.action}{" "}
                  {permission.attributes ? `(${permission.attributes})` : ""}
                </Tag>
              </div>
            );
          })}
        </>
      ),
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      sortOrder: roleStore.sortField === "createdAt" && roleStore.sortOrder,
      ...getColumnDateProps("Date de création"),
      defaultFilteredValue: roleStore.filters && roleStore.filters.createdAt,
    },
    {
      title: "Actions",
      width: 150,
      key: "action",
      align: "left",
      render: (text, record) => (
        <>
          <Link to={`/admin/users/roles/detail/${record.id}`}>
            <Button
              type="default"
              shape="circle"
              icon={<EyeOutlined />}
              style={{ marginRight: 5 }}
            ></Button>
          </Link>
          <Link to={`/admin/users/roles/edit/${record.id}`}>
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              style={{ marginRight: 5 }}
            ></Button>
          </Link>
          <Popconfirm
            title="Supprimer ?"
            okText="Oui"
            cancelText="Annuler"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={async () => {
              await roleStore.deleteRecord(record.id);
              roleStore.getAll();
            }}
          >
            <Button
              type="danger"
              shape="circle"
              icon={<DeleteOutlined />}
            ></Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    // roleStore.setFilters(filters);
    // roleStore.setPagination(pagination);
    // roleStore.setSortField(sorter.field);
    // roleStore.setSortOrder(sorter.order);
    // roleStore.getAll();
    roleStore.getAll({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters,
    });
  };

  // const onCreate = (values) => {
  //   console.log("Received values of form: ", values);
  //   setVisibleForm(false);
  //   roleStore.getAll();
  // };

  return (
    <div>
      <PageHeader
        style={{ padding: "10px 0" }}
        title="Roles"
        subTitle="Liste"
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 20,
        }}
      >
        <Link to={`/admin/users/roles/add`}>
          <Button type="primary" icon={<PlusOutlined />}>
            Ajouter
          </Button>
        </Link>
      </div>
      <Table
        onChange={handleTableChange}
        locale={{
          emptyText: "Aucuns enregistrements",
        }}
        pagination={{
          ...roleStore.pagination,
          showTotal: (total, range) => `${range[0]}-${range[1]} / ${total}`,
          showPrevNextJumpers: false,
          locale: frFR,
        }}
        rowKey={(record) => record.id}
        loading={roleStore.loading}
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );
};

export default inject("roleStore")(observer(Roles));
