import { observable, action } from 'mobx'

class setCommonStore {
    @observable users = null
    @observable account = 'admin'

    @action
    setUsers(user) {
        this.users = user;
        // console.log(user);
    }

    @action
    setCurrentUser(account) {
        this.account = account;
    }

}

export default new setCommonStore();