import { observable, action, makeAutoObservable } from 'mobx';
import axios from 'axios'
import { BASE_URL } from '../urlConfig'
import ApiService from "./../utils/api";
import { notification } from 'antd';
var _ = require('lodash');

class LoginStore {
    constructor() {
        makeAutoObservable(this)
        if (localStorage.getItem('isAuthenticated')) {
            this.refreshRoles();
        }
    }

    @observable loading = false;
    @observable Token = null;
    @observable errors = null;
    @observable isLogged = false;
    @observable account = null;
    @observable roles = [];

    @action
    async refreshRoles() {
        const resp = await ApiService.get('/auth/me');
        if (resp) {
            const test = _.map(resp.data.roles, function (o) {
                return o.permissions;
            });
            // console.log(_.uniqBy(_.flattenDeep(test), 'id'))
            await this.setRoles(_.uniqBy(_.flattenDeep(test), 'id'))
        } else {
            await localStorage.removeItem('token');
            await localStorage.removeItem('isAuthenticated')
            document.location.href = "/login"
        }

    }



    @action
    async setRoles(values) {
        // console.log("set", values);
        this.roles = values;
    }


    @action
    setLoading(val) {
        this.loading = val;
    }

    @action
    login = async (email, password) => {
        this.setLoading(true)
        try {
            const result = await axios.post(`${BASE_URL}/auth/login`, {
                email, password
            })
            if (result.data.token) {
                localStorage.setItem('token', result.data.token);
                await this.refreshRoles()
                if (_.find(this.roles, { resource: "admin" })) {
                    localStorage.setItem('isAuthenticated', "true")
                } else {
                    notification.error({ message: "Non autorisé" })
                }
            }
            this.setLoading(false)
        } catch (error) {
            this.setLoading(false)
        }
    }

    @action
    logout = async () => {
        localStorage.clear();
        this.isLogged = false;
        this.Token = null;

    }




}


export default new LoginStore()