import React from "react";
import { Link } from "react-router-dom";
import { Result, Button } from "antd";

const NotAuthorized = () => (
  <Result
    status="403"
    title="403"
    subTitle="Accès non autorisé"
    extra={
      <Link to="/admin">
        <Button type="primary">Retour</Button>
      </Link>
    }
  />
);

export default NotAuthorized;
