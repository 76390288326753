import React, { useEffect, useState } from "react";
import { Form, Input, Spin, notification, Button, PageHeader } from "antd";
import ApiService from "./../../utils/api";
import { useParams, useHistory } from "react-router-dom";

import { ASSETS_URL } from "./../../urlConfig";
import Avatar from "./../../components/Avatar";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const AddEditCenter = () => {
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  let { id, action } = useParams();
  useEffect(() => {
    form.resetFields();
    async function fetchData() {
      const record = [];
      if (id) {
        const temp = await ApiService.get(`/centers/${id}`);
        console.log(temp);
        Object.keys(temp.data).forEach((key) => {
              if (key === "picture") {
                if(temp.data[key] !== null && temp.data[key] !== ''){
                  record.push({
                    name: [key],
                    value: [{ url: `${ASSETS_URL}/centers/${temp.data[key]}` }],
                  });
                }else{
                  record.push({
                    name: [key],
                    value: [],
                  });
                }
              } else {
                record.push({
                  name: [key],
                  value: temp.data[key],
              });
          }

          // record.push({
          //   name: [key],
          //   value: temp.data[key],
          // });

        });
      }
      await setFields(record);
      setLoading(false);
    }
    if (action === "edit" || action === "detail") {
      setLoading(true);
      fetchData();
    }
  }, [action, id, form]);

  const onFinish = async (values) => {
    if (action === "edit") {
      values["id"] = parseInt(id);
      await ApiService.put(`/centers/`, values);
      notification.success({ message: "Enregistrement modifié" });
      history.push(`/admin/centers`);
    }
    if (action === "add") {
      await ApiService.post(`/centers/`, values);
      notification.success({ message: "Enregistrement ajouté" });
      history.push(`/admin/centers`);
    }
    console.log("Success:", values);
  };

  return (
    <>
      <PageHeader
        style={{ padding: "10px 0" }}
        onBack={() => history.goBack()}
        title="Centres"
        subTitle={
          action === "edit"
            ? "Modifier"
            : action === "detail"
            ? "Détail"
            : "Ajout"
        }
      />
      <Spin spinning={loading}>
        <Form
          {...layout}
          form={form}
          fields={fields}
          name="form"
          style={{ maxWidth: 800, margin: "0 auto" }}
          onFinish={onFinish}
        >
          <Form.Item
            name="name"
            label="Nom"
            rules={[
              {
                required: true,
                message: "Nom obligatoire",
              },
            ]}
          >
            <Input disabled={action === "detail"} />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Téléphone"
            rules={[
              {
                required: true,
                message: "Téléphone obligatoire",
              },
            ]}
          >
            <Input disabled={action === "detail"} />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: "Email obligatoire",
              },
            ]}
          >
            <Input disabled={action === "detail"} />
          </Form.Item>
          <Form.Item
            name="picture"
            label="Image"
            valuePropName="fileList"
            getValueFromEvent={(e) => e && e.fileList}
            extra={action !== "detail" ? "format jpg/jpeg/png" : null}
          >
            <Avatar disabled={action === "detail"} />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
            {action === "detail" && (
              <Button type="default" onClick={() => history.goBack()}>
                Retour
              </Button>
            )}
            {action === "edit" && (
              <Button type="primary" htmlType="submit">
                Modifier
              </Button>
            )}
            {action === "add" && (
              <Button type="primary" htmlType="submit">
                Ajouter
              </Button>
            )}
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};

export default AddEditCenter;
