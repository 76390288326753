import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Spin,
  Select,
  notification,
  Button,
  PageHeader,
} from "antd";
import ApiService from "./../../utils/api";
import { useParams, useHistory } from "react-router-dom";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const AddEditRole = () => {
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [children, setChildren] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  let { id, action } = useParams();
  useEffect(() => {
    form.resetFields();
    async function fetchData() {
      const record = [];
      if (id) {
        const temp = await ApiService.get(`/roles/${id}`);
        Object.keys(temp.data).forEach((key) => {
          if (key === "permissions") {
            console.log(temp.data[key]);
            let idModified = temp.data[key].map((obj) => {
              return {
                ...obj,
                key: obj.id,
              };
            });
            record.push({
              name: [key],
              value: idModified,
            });
          } else {
            record.push({
              name: [key],
              value: temp.data[key],
            });
          }
        });
      }
      const permissions = await ApiService.get(`/permissions`, {pagination:{current:1,pageSize:9999}});
      await setChildren(permissions.rows);
      if (id) {
        await setFields(record);
      }
      setLoading(false);
    }
    if (action === "edit" || action === "detail" || action === "add") {
      setLoading(true);
      fetchData();
    }
  }, [action, id, form]);

  const onFinish = async (values) => {
    let newPermissions = [];
    if (values.permissions) {
      newPermissions = values.permissions.map((e) => {
        return {
          id: e.key,
        };
      });
    }
    values["permissions"] = newPermissions;
    if (action === "edit") {
      values["id"] = parseInt(id);
      await ApiService.put(`/roles/`, values);
      notification.success({ message: "Enregistrement modifié" });
      history.push(`/admin/users/roles`);
    }
    if (action === "add") {
      await ApiService.post(`/roles/`, values);
      notification.success({ message: "Enregistrement ajouté" });
      history.push(`/admin/users/roles`);
    }
    console.log("Success:", values);
  };

  return (
    <>
      <PageHeader
        style={{ padding: "10px 0" }}
        onBack={() => history.goBack()}
        title="Roles"
        subTitle={
          action === "edit"
            ? "Modifier"
            : action === "detail"
            ? "Détail"
            : "Ajout"
        }
      />
      <Spin spinning={loading}>
        <Form
          {...layout}
          form={form}
          fields={fields}
          name="form"
          style={{ maxWidth: 800, margin: "0 auto" }}
          onFinish={onFinish}
        >
          <Form.Item
            name="name"
            label="Nom"
            rules={[
              {
                required: true,
                message: "Nom obligatoire",
              },
            ]}
          >
            <Input disabled={action === "detail"} />
          </Form.Item>
          <Form.Item label="Permissions" name="permissions">
            <Select
              allowClear
              mode="multiple"
              labelInValue
              disabled={action === "detail"}
            >
              {children.map((permission, i) => (
                <Select.Option value={permission.id} key={permission.id}>
                  <strong>{permission.resource} :</strong> {permission.action}{" "}
                  {permission.attributes ? `(${permission.attributes})` : ""}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
            {action === "detail" && (
              <Button type="default" onClick={() => history.goBack()}>
                Retour
              </Button>
            )}
            {action === "edit" && (
              <Button type="primary" htmlType="submit">
                Modifier
              </Button>
            )}
            {action === "add" && (
              <Button type="primary" htmlType="submit">
                Ajouter
              </Button>
            )}
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};

export default AddEditRole;
