import React, { useEffect, useState } from "react";
import { Form, Input, Spin, notification, Button, PageHeader } from "antd";
import ApiService from "./../../utils/api";
import { useParams, useHistory } from "react-router-dom";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const AddEditPermission = () => {
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  let { id, action } = useParams();
  useEffect(() => {
    form.resetFields();
    async function fetchData() {
      const record = [];
      if (id) {
        const temp = await ApiService.get(`/permissions/${id}`);
        Object.keys(temp.data).forEach((key) => {
          record.push({
            name: [key],
            value: temp.data[key],
          });
        });
      }
      await setFields(record);
      setLoading(false);
    }
    if (action === "edit" || action === "detail") {
      setLoading(true);
      fetchData();
    }
  }, [action, id, form]);

  const onFinish = async (values) => {
    if (action === "edit") {
      values["id"] = parseInt(id);
      await ApiService.put(`/permissions/`, values);
      notification.success({ message: "Enregistrement modifié" });
      history.push(`/admin/users/permissions`);
    }
    if (action === "add") {
      await ApiService.post(`/permissions/`, values);
      notification.success({ message: "Enregistrement ajouté" });
      history.push(`/admin/users/permissions`);
    }
    console.log("Success:", values);
  };

  return (
    <>
      <PageHeader
        style={{ padding: "10px 0" }}
        onBack={() => history.goBack()}
        title="Permissions"
        subTitle={
          action === "edit"
            ? "Modifier"
            : action === "detail"
            ? "Détail"
            : "Ajout"
        }
      />
      <Spin spinning={loading}>
        <Form
          {...layout}
          form={form}
          fields={fields}
          name="form"
          style={{ maxWidth: 800, margin: "0 auto" }}
          onFinish={onFinish}
        >
          <Form.Item
            name="resource"
            label="Ressource"
            rules={[
              {
                required: true,
                message: "Ressource obligatoire",
              },
            ]}
          >
            <Input disabled={action === "detail"} />
          </Form.Item>
          <Form.Item
            name="action"
            label="Action"
            rules={[
              {
                required: true,
                message: "Action obligatoire",
              },
            ]}
          >
            <Input disabled={action === "detail"} />
          </Form.Item>
          <Form.Item name="attributes" label="Attributs">
            <Input disabled={action === "detail"} />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
            {action === "detail" && (
              <Button type="default" onClick={() => history.goBack()}>
                Retour
              </Button>
            )}
            {action === "edit" && (
              <Button type="primary" htmlType="submit">
                Modifier
              </Button>
            )}
            {action === "add" && (
              <Button type="primary" htmlType="submit">
                Ajouter
              </Button>
            )}
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};

export default AddEditPermission;
