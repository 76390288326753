import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Spin,
  notification,
  Button,
  PageHeader,
  Space,
  TimePicker,
  DatePicker,
  Popconfirm,
  Transfer,
} from "antd";
import ApiService from "./../../utils/api";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import {
  PlusOutlined,
  MinusCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import locale from 'antd/es/date-picker/locale/fr_FR';


const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const AddEditPath = () => {
  const format = "HH:mm";
  const dateFormat = "DD/MM/YYYY";
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const history = useHistory();
  let { id, action } = useParams();
  useEffect(() => {
    form.resetFields();
    async function fetchData() {
      const record = [];
      if (id) {
        const temp = await ApiService.get(`/paths/${id}`);
        // console.log(temp);
        Object.keys(temp.data).forEach((key) => {
          switch (key) {
            case "hours":
              // console.log(temp.data[key]);
              var values = temp.data[key].map((elt) => {
                // console.log(elt);


                return {
                  id: elt.id,
                  hour: moment(elt.hour, format),
                  users: elt.users.map((user) => user.id),
                };
              });
              // console.log(values);
              record.push({
                name: [key],
                value: values,
              });
              break;
            case "date":
              const convertDate = moment(temp.data[key]);
              // console.log(moment(temp.data[key]).format(dateFormat));
              record.push({
                name: [key],
                //value: convertDate.format(dateFormat),
                value: convertDate,
              });
              // console.log(record);
              break;
            default:
              record.push({
                name: [key],
                value: temp.data[key],
              });
              break;
          }
        });
      }
      // record.push({
      //   name: "hours",
      //   value: [
      //     {
      //       hour: moment("23:10", format),
      //     },
      //   ],
      // });

      // record.push({
      //   name: "hours",
      //   value: [],
      // });
      const users = await ApiService.get(`/users`,{
        pagination: {current:1,pageSize:999999999}
      });
      await setUsers(users.rows);

      if (id) {
        // console.log(record);
        await setFields(record);
      }

      setLoading(false);
    }
    if (action === "edit" || action === "detail" || action === "add") {
      setLoading(true);
      fetchData();
    }
  }, [action, id, form]);

  const onFinish = async (values) => {

    var formatHours = values.hours.map((elt) => {
      // console.log(elt);
      let objOut = {
        hour: elt.hour.format(format),
        users: elt.users ? elt.users.map((user) => {
          return { id: user }
        }) : [],
      };
      if (elt.id) {
        objOut["id"] = elt.id;
      }
      return objOut;
    });
    values.hours = formatHours;

    // console.log(values);    
    // return;

    if (action === "edit") {
      values["id"] = parseInt(id);
      await ApiService.put(`/paths/`, values);
      notification.success({ message: "Enregistrement modifié" });
      history.push(`/admin/paths`);
    }
    if (action === "add") {
      await ApiService.post(`/paths/`, values);
      notification.success({ message: "Enregistrement ajouté" });
      history.push(`/admin/paths`);
    }
    // console.log("Success:", values);
  };

  const timeActions = (form, field, remove) => {
    return (
      <>
        {form.getFieldValue("hours")[field.key] &&
        form.getFieldValue("hours")[field.key].id ? (
          <Popconfirm
            title="Supprimer ? (attention, tous les trajets liés à cet horaire seront supprimés)"
            okText="Oui"
            cancelText="Annuler"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={async () => {
              remove(field.name);
            }}
          >
            <MinusCircleOutlined />
          </Popconfirm>
        ) : (
          <MinusCircleOutlined onClick={() => remove(field.name)} />
        )}
      </>
    );
  };

  return (
    <>
      <PageHeader
        style={{ padding: "10px 0" }}
        onBack={() => history.goBack()}
        title="Trajets"
        subTitle={
          action === "edit"
            ? "Modifier"
            : action === "detail"
            ? "Détail"
            : "Ajout"
        }
      />
      <Spin spinning={loading}>
        <Form
          {...layout}
          form={form}
          fields={fields}
          name="form"
          style={{ maxWidth: 800, margin: "0 auto" }}
          onFinish={onFinish}
        >
          <Form.Item
            name="date"
            label="Date du transport"
            rules={[
              {
                required: true,
                message: "Date obligatoire",
              },
            ]}
          >
            <DatePicker format={dateFormat} locale={locale} disabled={action === "detail"} />
          </Form.Item>

          <Form.Item
            name="from"
            label="Départ"
            rules={[
              {
                required: true,
                message: "Départ obligatoire",
              },
            ]}
          >
            <Input disabled={action === "detail"} />
          </Form.Item>

          <Form.Item
            name="to"
            label="Arrivée"
            rules={[
              {
                required: true,
                message: "Arrivée obligatoire",
              },
            ]}
          >
            <Input disabled={action === "detail"} />
          </Form.Item>

          <Form.Item name="hours" label="Horaires" required={true}>
            <Form.List
              name="hours"
              rules={[
                {
                  required: true,
                  message: "Au moins un horaire obligatoire",
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field) => (
                    <div key={field.key} style={{ display: "flex", flexDirection: 'row', borderBottom: '1px solid #d9d9d9', marginBottom: 20 }}>
                      <Space
                        key={field.key}
                        style={{ display: "flex", marginBottom: 5 }}
                        align="baseline"
                      >
                        <Form.Item
                          disabled={action === "detail"}
                          {...field}
                          name={[field.name, "hour"]}
                          fieldKey={[field.fieldKey, "hour"]}
                          rules={[
                            { required: true, message: "Horaire manquant" },
                          ]}
                          // initialValue={moment("12:00", format)}
                        >
                          <TimePicker
                            disabled={action === "detail"}
                            minuteStep={5}
                            showNow={false}
                            onSelect={(time) => {
                              const fields = form.getFieldsValue();
                              const { hours } = fields;
                              // if (hours[field.key]) {
                              //   Object.assign(hours[field.key], { hour: time });
                              // } else {
                              //   hours[field.key] = {
                              //     hour: time,
                              //   };
                              // }
                              if (hours[field.name]) {
                                Object.assign(hours[field.name], { hour: time });
                              } else {
                                hours[field.name] = {
                                  hour: time,
                                };
                              }
                              form.setFieldsValue({ hours });
                            }}
                            format={format}
                          />
                        </Form.Item>
                      </Space>
                      <Space
                        key={'users_'+field.key}
                        style={{ display: "flex", marginBottom: 5, marginLeft: 20 }}
                        align="baseline"
                      >
                        <Form.Item name={[field.name, "users"]} >
                          <Transfer
                            rowKey={record => record.id}
                            dataSource={users}
                            showSearch
                            filterOption={(inputValue, option) => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1}
                            locale={{
                              searchPlaceholder: 'Recherche',
                              itemUnit: 'utilisateur',
                              itemsUnit: 'utilisateurs',
                              remove: 'supprimer',
                              selectAll: 'Sélectionner tous',
                              selectCurrent: 'Sélectionner',
                              selectInvert: 'Inverser la sélection',
                              removeAll: 'Supprimer tous',
                              removeCurrent: 'Supprimer'
                            }}
                            // targetKeys={fields[field.name].users}
                            targetKeys={form.getFieldValue(['hours',[field.name],'users'])}
                            // onChange={(targetKeys) => {
                            //   setTargetKeys(targetKeys);
                            // }}
                            render={item => item.name}
                          />
                      </Form.Item>

                        {action !== "detail" && timeActions(form, field, remove)}
                      </Space>
                    </div>
                  ))}

                  {action !== "detail" && (
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => {
                          add();
                        }}
                        block
                        icon={<PlusOutlined />}
                      >
                        Ajouter une heure de départ
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  )}
                </>
              )}
            </Form.List>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
            {action === "detail" && (
              <Button type="default" onClick={() => history.goBack()}>
                Retour
              </Button>
            )}
            {action === "edit" && (
              <Button type="primary" htmlType="submit">
                Modifier
              </Button>
            )}
            {action === "add" && (
              <Button type="primary" htmlType="submit">
                Ajouter
              </Button>
            )}
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};

export default AddEditPath;
