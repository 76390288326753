import React from 'react';
import { PageHeader } from "antd";

const AdminPage = () => {
    return (
        <div>
            <PageHeader
                style={{ padding: "10px 0 30px" }}
                title="Tableau de bord"
            />
            <div>This is Admin Hompage</div>
        </div>
    );
}

export default AdminPage;
