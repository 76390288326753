import React, { useEffect } from "react";
import { observer, inject } from "mobx-react";
import { Table, Button, Popconfirm, PageHeader } from "antd";
import useTableSearch from "../../components/useTableSearch";
import useTableDate from "../../components/useTableDate";
import { Link } from "react-router-dom";
import frFR from "rc-pagination/lib/locale/fr_FR";

import {
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";

const Centers = ({ centerStore }) => {
  useEffect(() => {
    centerStore.getAll();
  }, [centerStore]);

  const dataSource = centerStore.records;
  const getColumnDateProps = useTableDate();
  const getColumnSearchProps = useTableSearch();

  const columns = [
    {
      title: "Id",
      width: 10,
      dataIndex: "id",
      key: "id",
      sorter: true,
      sortOrder: centerStore.sortField === "id" && centerStore.sortOrder,
      defaultSortOrder: "ascend",
      ...getColumnSearchProps("Id"),
      defaultFilteredValue: centerStore.filters && centerStore.filters.id,
    },
    {
      title: "Nom",
      dataIndex: "name",
      key: "name",
      sorter: true,
      sortOrder: centerStore.sortField === "name" && centerStore.sortOrder,
      ...getColumnSearchProps("Nom"),
      defaultFilteredValue: centerStore.filters && centerStore.filters.name,
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      sortOrder: centerStore.sortField === "createdAt" && centerStore.sortOrder,
      ...getColumnDateProps("Date de création"),
      defaultFilteredValue:
        centerStore.filters && centerStore.filters.createdAt,
    },
    {
      title: "Actions",
      width: 150,
      key: "action",
      align: "left",
      render: (text, record) => (
        <>
          <Link to={`/admin/centers/detail/${record.id}`}>
            <Button
              type="default"
              shape="circle"
              icon={<EyeOutlined />}
              style={{ marginRight: 5 }}
            ></Button>
          </Link>
          <Link to={`/admin/centers/edit/${record.id}`}>
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              style={{ marginRight: 5 }}
            ></Button>
          </Link>

          <Popconfirm
            title="Supprimer ?"
            okText="Oui"
            cancelText="Annuler"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={async () => {
              await centerStore.deleteRecord(record.id);
              centerStore.getAll();
            }}
          >
            <Button
              type="danger"
              shape="circle"
              icon={<DeleteOutlined />}
            ></Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    centerStore.getAll({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters,
    });
  };

  return (
    <div>
      <PageHeader
        style={{ padding: "10px 0" }}
        title="Centres"
        subTitle="Liste"
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 20,
        }}
      >
        <Link to={`/admin/centers/add`}>
          <Button type="primary" icon={<PlusOutlined />}>
            Ajouter
          </Button>
        </Link>
      </div>
      <Table
        onChange={handleTableChange}
        locale={{
          emptyText: "Aucuns enregistrements",
        }}
        pagination={{
          ...centerStore.pagination,
          showTotal: (total, range) => `${range[0]}-${range[1]} / ${total}`,
          showPrevNextJumpers: false,
          locale: frFR,
        }}
        rowKey={(record) => record.id}
        loading={centerStore.loading}
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );
};

export default inject("centerStore")(observer(Centers));
