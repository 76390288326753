import React, { useEffect, useState } from "react";
import { 
  Form, 
  Input, 
  Select,
  Spin, 
  notification, 
  Button, 
  PageHeader,
  DatePicker,
  Modal,
  Radio,
  Table,
  Upload,
  message
} from "antd";
import ApiService from "./../../utils/api";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import Recorder from "voice-recorder-react";
import locale from 'antd/es/date-picker/locale/fr_FR';
import AudioUI from "./AudioUI";
import { AudioOutlined, UploadOutlined } from '@ant-design/icons';

import { ASSETS_URL, BASE_URL } from "./../../urlConfig";
import authHeader from "./../../utils/auth-header";


import useTableSearch from "../../components/useTableSearch";

import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  QuestionCircleTwoTone,
} from '@ant-design/icons';
import mealStore from "../../stores/mealStore";

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
};

const AddEditMeal = () => {
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalRecordVisible, setIsModalRecordVisible] = useState(false);
  const [isModalPlayVisible, setIsModalPlayVisible] = useState(false);
  const [audioFile, setAudioFile] = useState();
  const [customRecord, setCustomRecord] = useState(false);

  const [playUrl, setPlayUrl] = useState(null);


  const [users, setUsers] = useState([]);
  const dateFormat = "DD/MM/YYYY";
  const history = useHistory();
  let { id, action } = useParams();
  useEffect(() => {
    form.resetFields();
    async function fetchData() {
      const record = [];
      if (id) {
        const temp = await ApiService.get(`/meals/${id}`);
        Object.keys(temp.data).forEach((key) => {
          switch (key) {
            case "date":
              const convertDate = moment(temp.data[key]);
              record.push({
                name: [key],
                value: convertDate,
              });
              break;
            case "audio":
              // setAudioFile({url:temp.url, uid: 'name', name: 'name.webm', blob: temp.blob})
              if(temp.data[key] !== null && temp.data[key] !== ''){
                setCustomRecord(true)
                // setAudioFile({ name: temp.data[key], url: `${ASSETS_URL}/meals/${temp.data[key]}` });
                console.log(temp.data[key]);
                record.push({
                  name: [key],
                  value: [{ name: temp.data[key], url: `${ASSETS_URL}/meals/${temp.data[key]}` }],
                });
              }else{
                record.push({
                  name: [key],
                  value: [],
                });
              }
              break;
            default:
              record.push({
                name: [key],
                value: temp.data[key],
              });
              break;
          }
        });
      }
      const users = await ApiService.get(`/users`,{
        pagination: {current:1,pageSize:999999999}
      });
      const tempUsers = record.filter((elt) => elt.name[0] === 'users');
      users.rows.map((elt) => {
        if(tempUsers.length > 0){
          const temp = tempUsers[0].value.filter((item) => {
            return item.id === elt.id
          })
          if (temp.length > 0) {
            elt.presence = temp[0].UsersMeals.presence;
          }else{
            elt.presence = undefined;
          }
        } else {
          elt.presence = undefined;
        }
        return elt;
      })
      await setUsers(users.rows);
      if (id) {
        await setFields(record);
      }
      setLoading(false);
    }
    if (action === "edit" || action === "detail" || action === "add") {
      setLoading(true);
      fetchData();
    }
  }, [action, id, form]);

  const onFinish = async (values) => {
    let newUsers = [];
    users.forEach((elt) => {
      if(elt.presence !== undefined){
        newUsers.push({
          id: elt.id,
          presence: elt.presence,
        })
      }
    })

    const formattedDate = values.date.format("YYYY-MM-DD");
    values.date = formattedDate;
    values["users"] = newUsers;
    if (action === "edit") {
      values["id"] = parseInt(id);
      await ApiService.put(`/meals/`, values);
      notification.success({ message: "Enregistrement modifié" });
      history.push(`/admin/meals`);
    }
    if (action === "add") {
      await ApiService.post(`/meals/`, values);
      notification.success({ message: "Enregistrement ajouté" });
      history.push(`/admin/meals`);
    }

  };

  const getColumnSearchProps = useTableSearch();

  // const normFile = (e) => {
  //   console.log('Upload event:', e);
  
  //   if (Array.isArray(e)) {
  //     return e;
  //   }
  
  //   return e && e.fileList;
  // };

  const beforeUpload = file => {
    console.log(file);
    if (file.type !== 'video/webm' && file.type !== 'audio/webm' && file.type !== 'audio/ogg' && file.type !== 'audio/wav') {
      message.error(`${file.name} n'est pas autorisé`);
    }
    return file.type === 'video/webm' || file.type === 'audio/webm' || file.type === 'audio/ogg' || file.type === 'audio/wav' ? true : Upload.LIST_IGNORE;
  }

  return (
    <>
      <PageHeader
        style={{ padding: "10px 0" }}
        onBack={() => history.goBack()}
        title="Repas"
        subTitle={
          action === "edit"
            ? "Modifier"
            : action === "detail"
            ? "Détail"
            : "Ajout"
        }
      />
      <Spin spinning={loading}>
        <Form
          {...layout}
          form={form}
          fields={fields}
          name="form"
          style={{ maxWidth: 800, margin: "0 auto" }}
          onFinish={onFinish}
        >
          <Form.Item
            name="date"
            label="Date du repas"
            rules={[
              {
                required: true,
                message: "Date obligatoire",
              },
            ]}
          >
            <DatePicker format={dateFormat} locale={locale} disabled={action === "detail"} />
          </Form.Item>
          <Form.Item
            label="Période"
            name="period"
            rules={[{ required: true, message: "Sélectionner la période" }]}
          >
            <Select
              disabled={action === "detail"}
            >
                <Select.Option value='am' key='am'>
                  Matin
                </Select.Option>
                <Select.Option value='pm' key='pm'>
                  Soir
                </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="starter"
            label="Entrée"
          >
            <Input.TextArea rows={2} disabled={action === "detail"} />
          </Form.Item>
          <Form.Item
            name="main"
            label="Plat"
          >
            <Input.TextArea rows={2} disabled={action === "detail"} />
          </Form.Item>
          <Form.Item
            name="dessert"
            label="Dessert"
          >
            <Input.TextArea rows={2} disabled={action === "detail"} />
          </Form.Item>

          <Form.Item
            name="audio"
            label="Audio"
            valuePropName="fileList"
            getValueFromEvent={(e) => e && e.fileList}
            extra={action !== "detail" ? "format webm/wav" : null}
          >
            <Upload 
              maxCount={1}
              multiple={false}
              // name="audio" 
              listType="text"
              beforeUpload={beforeUpload}
              onRemove={() => {
                setCustomRecord(false)
              }}
              onChange={(e) => {
                console.log(e)
                if(e.fileList.length > 0){
                  setCustomRecord(true)
                }
              }}
              onPreview={(e) => {
                console.log('onPreview', e);
                if(e.response && e.response.tempFilePath){
                  setPlayUrl((ASSETS_URL + '/' + e.response.tempFilePath).replace('/uploads', ''))
                }else{
                  setPlayUrl(e.url)
                }
                
                setIsModalPlayVisible(true)
              }}
              action={`${BASE_URL}/meals/upload`}
              headers={authHeader()}
              locale={{
                uploading: "Téléversement...",
                removeFile: "Supprimer le fichier",
                uploadError: "Erreur",
                // previewFile: "Voir le fichier",
                downloadFile: "Télécharger",
              }}
            >
                <Button disabled={customRecord} icon={<UploadOutlined />}>Fichier</Button>
                <Button disabled={customRecord} icon={<AudioOutlined />} onClick={(e)=>{
                  e.stopPropagation();
                  setIsModalRecordVisible(true);
                }} 
                >Enregistrement</Button>
            </Upload>
          </Form.Item>


          <Modal
            title="Lecture"
            visible={isModalPlayVisible}
            onCancel={() => {
              setIsModalPlayVisible(false)
            }}
            footer={[
              <Button key="submit" type="primary" onClick={() => {
                setIsModalPlayVisible(false)
              }} onCancel={() => {
                setIsModalPlayVisible(false)
              }}>
              Ok
            </Button>
            ]}
          >
            <div>
              <audio src={playUrl} controls style={{width: '100%'}} />
            </div>
          </Modal>

          <Modal title="Enregistrement" visible={isModalRecordVisible} 
            onOk={async ()=>{
              const response = await mealStore.uploadAudio(audioFile.blob);
              console.log(response)
              if (response.data) {
                const tempAudioFile = audioFile;
                tempAudioFile.name = response.md5+'.webm';
                setAudioFile(tempAudioFile)
                audioFile.response = response;
                delete audioFile.url;
                form.setFieldsValue({
                  audio: [audioFile]
                });
                setIsModalRecordVisible(false);
                setCustomRecord(true)
                console.log(audioFile)  
              }
            }}
            onCancel={()=>{
              setIsModalRecordVisible(false);
              form.setFieldsValue({
                audio: []
              });
            }}
          >
            <div>
            <Recorder Render={AudioUI} 
              mimeTypeToUseWhenRecording="audio/webm"
              handleReset={(temp)=>{
                // console.log('handleReset', temp)
                setAudioFile({});
              }}
              handleAudioStop={(temp) => {
                console.log('handleAudioStop', temp)
                setAudioFile({url:temp.url, uid: 'name', name: 'name.webm', blob: temp.blob})
              }}
            />
          </div>
          </Modal>

          <Form.Item
            name="registers"
            label="Inscriptions"
          >
            <Table
              rowKey={record => record.id}
              columns={[
                {
                  title: 'Name',
                  dataIndex: 'name',
                  render: (text) => <span>{text}</span>,
                  ...getColumnSearchProps("Nom"),
                  onFilter: (value, record) => {
                    let filter;
                    filter = value.toUpperCase();
                    if (record.name.toUpperCase().indexOf(filter) > -1) {
                      return record;
                    }
                  },
                  sorter: (a, b) => {
                    var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                    var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                  
                    // names must be equal
                    return 0;
                  },
                  // sortDirections: ['ascend'],
                },
                {
                  title: 'Présence',
                  dataIndex: 'presence',
                  render: (text, record) => {
                    return (
                      <Radio.Group name={'radio_' + record.id} onChange={(e)=>{
                          const currentId = parseInt(e.target.name.replace('radio_',''));
                          setUsers(users.map(el => (el.id === currentId ? {...el, presence: e.target.value} : el)))
                        }} value={text} >
                        <Radio.Button value={true}><CheckCircleTwoTone twoToneColor="#52c41a" /></Radio.Button>
                        <Radio.Button value={false}><CloseCircleTwoTone twoToneColor="#eb2f96" /></Radio.Button>
                        <Radio.Button value={undefined}><QuestionCircleTwoTone twoToneColor="#cccccc" /></Radio.Button>
                      </Radio.Group>)
                  },
                  filters: [
                    {
                      text: 'Oui',
                      value: true,
                    },
                    {
                      text: 'Non',
                      value: false,
                    },
                    {
                      text: 'Inconnu',
                      value: 0,
                    },
                  ],
                  onFilter: (value, record) => {
                    if(value === 0){
                      if(record.presence === undefined){
                        return record;
                      }
                    }else{
                      if(record.presence === value){
                        return record;
                      }
                    }
                  },
                },
              ]}
              dataSource={users}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
            {action === "detail" && (
              <Button type="default" onClick={() => history.goBack()}>
                Retour
              </Button>
            )}
            {action === "edit" && (
              <Button type="primary" htmlType="submit">
                Modifier
              </Button>
            )}
            {action === "add" && (
              <Button type="primary" htmlType="submit">
                Ajouter
              </Button>
            )}
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};

export default AddEditMeal;
