import React from 'react';
import './App.less';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
// import Home from './pages/Home'
import Login from './pages/Login'
import PrivateRoute from './components/PrivateRouter'
import Layout from './components/Layout/Layout.jsx'
import AdminPage from './pages/Admin/index'

import { Provider } from 'mobx-react'


import LoginStore from './stores/LoginStore'
import MemberStore from './stores/memberStore'
import userStore from './stores/userStore'
import roleStore from './stores/roleStore'
import permissionStore from './stores/permissionStore'
import setCommonStore from './stores/setCommon'
import sessionStore from './stores/sessionStore'
import centerStore from './stores/centerStore'
import pathStore from './stores/pathStore'
import mealStore from './stores/mealStore'



import Account from './pages/Account/List.jsx'
import AddEditAccount from './pages/Account/AddEdit.jsx'

import Role from './pages/Role/List.jsx'
import AddEditRole from './pages/Role/AddEdit.jsx'

import Permission from './pages/Permission/List.jsx'
import AddEditPermission from './pages/Permission/AddEdit.jsx'

import Center from './pages/Center/List.jsx'
import AddEditCenter from './pages/Center/AddEdit.jsx'

import Meal from './pages/Meal/List.jsx'
import AddEditMeal from './pages/Meal/AddEdit.jsx'

import Path from './pages/Path/List.jsx'
import AddEditPath from './pages/Path/AddEdit.jsx'

import Session from './pages/Session/List.jsx'

import NotFound from './pages/NotFound';
import NotAuthorized from './pages/NotAuthorized';

// var _ = require("lodash");

const store = {
  LoginStore,
  MemberStore,
  setCommonStore,
  userStore,
  roleStore,
  sessionStore,
  permissionStore,
  centerStore,
  pathStore,
  mealStore
}

const App = () => {

  return (
    <Provider  {...store}>
      <BrowserRouter>
        <React.Fragment>
          <Switch>

            <Route path="/login" exact component={Login} />
            {/* <Route exact path="/" component={Home} /> */}

            <Route exact path="/">
              <Redirect to="/admin" />
            </Route>
            
            
            {/* <PrivateRoute path="/admin" exact component={Dashboard} /> */}
            <PrivateRoute
              exact
              layout={Layout}
              path="/admin"
              name="admin"
              component={AdminPage}
            />

            <PrivateRoute
              exact
              layout={Layout}
              path="/admin/users/accounts"
              name="users"
              component={Account}
            />

            <PrivateRoute
              exact
              layout={Layout}
              path="/admin/users/accounts/:action(add|edit|detail)/:id?"
              name="users"
              component={AddEditAccount}
            />

            <PrivateRoute
              exact
              layout={Layout}
              path="/admin/users/roles"
              name="roles"
              component={Role}
            />

            <PrivateRoute
              exact
              layout={Layout}
              path="/admin/users/roles/:action(add|edit|detail)/:id?"
              name="roles"
              component={AddEditRole}
            />

            <PrivateRoute
              exact
              layout={Layout}
              path="/admin/users/permissions"
              name="permissions"
              component={Permission}
            />
            <PrivateRoute
              exact
              layout={Layout}
              path="/admin/users/permissions/:action(add|edit|detail)/:id?"
              name="permissions"
              component={AddEditPermission}
            />

            <PrivateRoute
              exact
              layout={Layout}
              path="/admin/users/sessions"
              name="sessions"
              component={Session}
            />

            <PrivateRoute
              exact
              layout={Layout}
              path="/admin/centers"
              name="centers"
              component={Center}
            />
            <PrivateRoute
              exact
              layout={Layout}
              path="/admin/centers/:action(add|edit|detail)/:id?"
              name="centers"
              component={AddEditCenter}
            />

            <PrivateRoute
              exact
              layout={Layout}
              path="/admin/meals"
              name="meals"
              component={Meal}
            />
            <PrivateRoute
              exact
              layout={Layout}
              path="/admin/meals/:action(add|edit|detail)/:id?"
              name="meals"
              component={AddEditMeal}
            />


            <PrivateRoute
              exact
              layout={Layout}
              path="/admin/paths"
              name="paths"
              component={Path}
            />
            <PrivateRoute
              exact
              layout={Layout}
              path="/admin/paths/:action(add|edit|detail)/:id?"
              name="paths"
              component={AddEditPath}
            />


            <Route exact path="/unauth" component={NotAuthorized} />
            <Route component={NotFound} />


          </Switch>
        </React.Fragment>

      </BrowserRouter>
    </Provider>
  )
};

export default App;