import React, { Fragment, useState } from "react";
import { Layout, Menu, Breadcrumb } from "antd";
import {
  UserOutlined,
  HomeOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  ShopOutlined,
  NodeIndexOutlined,
} from "@ant-design/icons";

import Logo from "../../assets/Logo.png";
import "./style.less";
import { inject, observer } from "mobx-react";

import { useHistory, useLocation, Link } from "react-router-dom";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

var _ = require("lodash");

const Dashboard = ({ children, LoginStore, setCommonStore }) => {
  // console.log(setCommonStore.users)
  const breadcrumbNameMap = {
    "/admin/users": {
      title: () => (
        <>
          <UserOutlined /> Utilisateurs
        </>
      ),
      link: false,
    },
    "/admin/users/accounts": {
      routeKey: 1,
      title: () => <>Comptes</>,
      link: true,
    },
    "/admin/users/roles": {
      routeKey: 2,
      title: () => <>Roles</>,
      link: true,
    },
    "/admin/users/roles/edit": {
      routeKey: 2,
      title: () => <>Modifier</>,
      link: true,
    },
    "/admin/users/roles/add": {
      routeKey: 2,
      title: () => <>Ajouter</>,
      link: true,
    },
    "/admin/users/roles/detail": {
      routeKey: 2,
      title: () => <>Détail</>,
      link: true,
    },
    "/admin/users/permissions": {
      routeKey: 3,
      title: () => <>Permissions</>,
      link: true,
    },
    "/admin/users/permissions/edit": {
      routeKey: 3,
      title: () => <>Modifier</>,
      link: true,
    },
    "/admin/users/permissions/add": {
      routeKey: 3,
      title: () => <>Ajouter</>,
      link: true,
    },
    "/admin/users/permissions/detail": {
      routeKey: 3,
      title: () => <>Détail</>,
      link: true,
    },
    "/admin/centers": {
      routeKey: 4,
      title: () => <>Centres</>,
      link: true,
    },
    "/admin/centers/edit": {
      routeKey: 4,
      title: () => <>Modifier</>,
      link: true,
    },
    "/admin/centers/add": {
      routeKey: 4,
      title: () => <>Ajouter</>,
      link: true,
    },
    "/admin/centers/detail": {
      routeKey: 4,
      title: () => <>Détail</>,
      link: true,
    },

    "/admin/paths": {
      routeKey: 4,
      title: () => <>Trajets</>,
      link: true,
    },
    "/admin/paths/edit": {
      routeKey: 4,
      title: () => <>Modifier</>,
      link: true,
    },
    "/admin/paths/add": {
      routeKey: 4,
      title: () => <>Ajouter</>,
      link: true,
    },
    "/admin/paths/detail": {
      routeKey: 4,
      title: () => <>Détail</>,
      link: true,
    },

    "/admin/users/sessions": {
      routeKey: 5,
      title: () => <>Sessions</>,
      link: true,
    },
    "/apps/2/detail": {
      title: () => <>Détail</>,
      link: true,
    },
  };

  const history = useHistory();
  const { pathname } = useLocation();

  const [open, setOpen] = useState(false);

  // console.log(useLocation().pathname)

  const pathSnippets = pathname.split("/").filter((i) => i);
  // console.log(pathSnippets);

  const startKey =
    "/" +
    pathSnippets[0] +
    "/" +
    pathSnippets[1] +
    (pathSnippets[2] ? "/" + pathSnippets[2] : "");
  // console.log(startKey);
  // console.log(startKey);

  const openKey = pathSnippets[1];
  // console.log(openKey);

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    if (breadcrumbNameMap[url] && breadcrumbNameMap[url].title !== "") {
      return (
        <Breadcrumb.Item key={url}>
          {breadcrumbNameMap[url].link ? (
            <Link to={url}>{breadcrumbNameMap[url].title()}</Link>
          ) : (
            breadcrumbNameMap[url].title()
          )}
        </Breadcrumb.Item>
      );
    } else {
      return null;
    }
  });
  const breadcrumbItems = [
    <Breadcrumb.Item key="admin">
      <Link to="/admin">
        <HomeOutlined /> Accueil
      </Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  const handleClickMenu = (e) => {
    e.key === "SignOut" && LoginStore.logout();
    history.push("/login");
  };
  const rightContent = [
    <Menu
      mode="horizontal"
      key="user"
      onClick={handleClickMenu}
      className="Account"
    >
      <SubMenu
        className="submenu"
        title={
          <Fragment>
            <span style={{ color: "red", marginRight: 4 }}>Bonjour,</span>
            <span> {setCommonStore.account}</span>
          </Fragment>
        }
      >
        <Menu.Item key="SignOut">
          <span style={{ color: "red" }}>Déconnexion</span>
        </Menu.Item>
      </SubMenu>
    </Menu>,
  ];

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider collapsible collapsed={open} trigger={null}>
        <div className="logo">
          <Link to="/admin">
            <img src={Logo} className="logo_img" alt="Atonis" />
          </Link>
        </div>
        <Menu
          mode="inline"
          defaultSelectedKeys={[startKey]}
          defaultOpenKeys={[openKey]}
          theme="dark"
        >
          {_.find(LoginStore.roles, { resource: "centers", action: "*" }) && (
            <Menu.Item icon={<ShopOutlined />} key="/admin/centers">
              <Link to="/admin/centers">Centres</Link>
            </Menu.Item>
          )}

          {_.find(LoginStore.roles, { resource: "paths", action: "*" }) && (
            <Menu.Item icon={<NodeIndexOutlined />} key="/admin/paths">
              <Link to="/admin/paths">Trajets</Link>
            </Menu.Item>
          )}

          {_.find(LoginStore.roles, { resource: "meals", action: "*" }) && (
            <Menu.Item icon={<ShopOutlined />} key="/admin/meals">
              <Link to="/admin/meals">Repas</Link>
            </Menu.Item>
          )}

          <SubMenu key="users" icon={<UserOutlined />} title="Utilisateurs">
            {_.find(LoginStore.roles, { resource: "users", action: "*" }) && (
              <Menu.Item key="/admin/users/accounts">
                <Link to="/admin/users/accounts">Comptes</Link>
              </Menu.Item>
            )}
            {_.find(LoginStore.roles, { resource: "roles", action: "*" }) && (
              <Menu.Item key="/admin/users/roles">
                <Link to="/admin/users/roles">Roles</Link>
              </Menu.Item>
            )}
            {_.find(LoginStore.roles, {
              resource: "permissions",
              action: "*",
            }) && (
              <Menu.Item key="/admin/users/permissions">
                <Link to="/admin/users/permissions">Permissions</Link>
              </Menu.Item>
            )}
            {_.find(LoginStore.roles, {
              resource: "sessions",
              action: "*",
            }) && (
              <Menu.Item key="/admin/users/sessions">
                <Link to="/admin/users/sessions">Sessions</Link>
              </Menu.Item>
            )}
          </SubMenu>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          {React.createElement(open ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: "trigger",
            onClick: () => setOpen(!open),
          })}
          <div style={{ float: "right" }} className="userLogout">
            {rightContent}
          </div>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
          }}
        >
          <Breadcrumb>{breadcrumbItems}</Breadcrumb>
          {children}
        </Content>
        <Layout.Footer style={{ textAlign: "center" }}>
          Atonis Technologies ©2021
        </Layout.Footer>
      </Layout>
    </Layout>
  );
};

export default inject("LoginStore", "setCommonStore")(observer(Dashboard));
