import { Row, Col, Form, Input, Button, Spin } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react'
import './index.less'
import { observer, inject } from 'mobx-react'
import { useHistory } from 'react-router-dom';


const Login = ({ LoginStore }) => {
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('')

    useEffect(() => {
        const isAuth = localStorage.getItem('isAuthenticated');
        // console.log(isAuth)
        if (isAuth) {
            history.push('/admin');
        }
    });

    const onFinish = async (values) => {
        await LoginStore.login(email, password)
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };
    const handleChangeEmail = e => {
        setEmail(e.target.value);
    }
    const handleChangePassword = e => {
        setPassword(e.target.value)
    }

    return (

        <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
            <Col span={8} />
            <Col span={8} className='form'>
                <Spin spinning={LoginStore.loading}>
                    <h2>Connexion</h2>
                    <Form
                        name="basic"
                        initialValues={{}}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            name="email"
                            onChange={handleChangeEmail}
                            rules={[{ required: true, message: 'Champ obligatoire' },
                            { type: 'email', message: 'Email invalide' }]}
                        >
                            <Input prefix={<MailOutlined className='input-icon' />} placeholder="E-mail" />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            onChange={handleChangePassword}
                            rules={[{ required: true, message: 'Champ obligatoire' }]}
                        >
                            <Input.Password prefix={<LockOutlined className='input-icon' />} placeholder="Mode de passe" />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" block>
                                Valider
                        </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </Col>
            <Col span={8} />
        </Row>


    );
};



// export default Login;
export default inject('LoginStore')(observer(Login))
