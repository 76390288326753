import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";
import { Table, Tag, Button, Popconfirm, PageHeader } from "antd";
import useTableSearch from "../../components/useTableSearch";
import useTableDate from "../../components/useTableDate";
import frFR from "rc-pagination/lib/locale/fr_FR";
import { Link } from "react-router-dom";
import {
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
// import centerStore from "../../stores/centerStore";

const Accounts = ({ userStore, centerStore }) => {
  useEffect(() => {
    async function fetchData() {
      userStore.getAll();
      await centerStore.getAll();
      let outTest = [];
      centerStore.records.forEach((element) => {
        // console.log(element);
        outTest.push({
          //value: element.id,
          value: element.id,
          text: element.name,
        });
      });
      setCenters(outTest);
      // console.log(outTest);
    }
    fetchData();
  }, [userStore, centerStore]);

  const [centers, setCenters] = useState([]);

  const dataSource = userStore.records;
  const getColumnDateProps = useTableDate();
  const getColumnSearchProps = useTableSearch();
  const columns = [
    {
      title: "Id",
      width: 10,
      dataIndex: "id",
      key: "id",
      sorter: true,
      sortOrder: userStore.sortField === "id" && userStore.sortOrder,
      ...getColumnSearchProps("Id"),
      defaultFilteredValue: userStore.filters && userStore.filters.id,
    },
    {
      title: "Nom",
      dataIndex: "name",
      key: "name",
      sorter: true,
      sortOrder: userStore.sortField === "name" && userStore.sortOrder,
      ...getColumnSearchProps("Nom"),
      defaultFilteredValue: userStore.filters && userStore.filters.name,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true,
      sortOrder: userStore.sortField === "email" && userStore.sortOrder,
      ...getColumnSearchProps("Email"),
      defaultFilteredValue: userStore.filters && userStore.filters.email,
    },
    {
      title: "Roles",
      key: "roles.name",
      dataIndex: "roles",
      sorter: true,
      sortOrder: userStore.sortField === "roles" && userStore.sortOrder,
      render: (roles) => (
        <>
          {roles.map((role) => {
            return <Tag key={role.id}>{role.name}</Tag>;
          })}
        </>
      ),
      filters: [
        { text: "Superadmin", value: "superadmin" },
        { text: "Admin", value: "admin" },
        { text: "Guest", value: "guest" },
        { text: "Educateur", value: "teacher" },
      ],
      defaultFilteredValue:
        userStore.filters && userStore.filters["roles.name"],
    },
    {
      title: "Centre",
      key: "centerId",
      dataIndex: "center",
      sorter: true,
      sortOrder: userStore.sortField === "center" && userStore.sortOrder,
      render: (center) => (
        <>
          <strong>{center && center.name ? center.name : ""}</strong>
        </>
      ),
      filters: centers,
      defaultFilteredValue: userStore.filters && userStore.filters.center,
    },
    {
      title: "Actif",
      key: "active",
      dataIndex: "active",
      render: (active) => (
        <Tag color={active ? "green" : "red"} key={active}>
          {active ? "Oui" : "Non"}
        </Tag>
      ),
      filters: [
        { text: "Actif", value: "1" },
        { text: "Inactif", value: "0" },
      ],
      defaultFilteredValue: userStore.filters && userStore.filters.active,
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      sortOrder: userStore.sortField === "createdAt" && userStore.sortOrder,
      ...getColumnDateProps("Date de création"),
      defaultFilteredValue: userStore.filters && userStore.filters.createdAt,
    },
    {
      title: "Actions",
      width: 150,
      key: "action",
      align: "left",
      render: (text, record) => (
        <>
          <Link to={`/admin/users/accounts/detail/${record.id}`}>
            <Button
              type="default"
              shape="circle"
              icon={<EyeOutlined />}
              style={{ marginRight: 5 }}
            ></Button>
          </Link>
          <Link to={`/admin/users/accounts/edit/${record.id}`}>
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              style={{ marginRight: 5 }}
            ></Button>
          </Link>
          <Popconfirm
            title="Supprimer ?"
            okText="Oui"
            cancelText="Annuler"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={async () => {
              await userStore.deleteRecord(record.id);
              userStore.getAll();
            }}
          >
            <Button
              type="danger"
              shape="circle"
              icon={<DeleteOutlined />}
            ></Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    userStore.getAll({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters,
    });
  };

  return (
    <div>
      <PageHeader
        style={{ padding: "10px 0" }}
        title="Comptes"
        subTitle="Liste"
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 20,
        }}
      >
        <Link to={`/admin/users/accounts/add`}>
          <Button type="primary" icon={<PlusOutlined />}>
            Ajouter
          </Button>
        </Link>
      </div>
      <Table
        onChange={handleTableChange}
        locale={{
          emptyText: "Aucuns enregistrements",
        }}
        pagination={{
          ...userStore.pagination,
          showTotal: (total, range) => {
            return `${range[0]}-${range[1]} / ${total}`;
          },
          showPrevNextJumpers: false,
          locale: frFR,
        }}
        rowKey={(record) => record.id}
        loading={userStore.loading}
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );
};

export default inject("userStore", "centerStore")(observer(Accounts));
