import React from "react";
import { Link } from "react-router-dom";
import { Result, Button } from "antd";

const NotFound = () => (
  <Result
    status="404"
    title="404"
    subTitle="Page inexistante"
    extra={
      <Link to="/admin">
        <Button type="primary">Retour</Button>
      </Link>
    }
  />
);

export default NotFound;
