import axios from 'axios';
import { notification } from 'antd';
import authHeader from './auth-header';
import { BASE_URL } from './../urlConfig'
// import { useHistory } from 'react-router-dom';


class ApiService {

    constructor() {

        axios.interceptors.response.use(res => res.data, async function (error) {
            console.log(error.response.data.code)
            notification.error({ message: error.response.data.message })
            if (error.response.data.code === 401) {
                // await localStorage.removeItem('token');
                // await localStorage.removeItem('isAuthenticated')
                // document.location.href = "/login"
            } else if (error.response.data.code === 403) {
                document.location.href = "/unauth"
            } else {
                return Promise.reject(error.response.data.message);
            }
        });
    }

    get(url, datas) {
        return axios.get(`${BASE_URL}${url}`, {
            headers: authHeader(),
            params: datas
        })
    }

    post(url, datas) {
        return axios.post(`${BASE_URL}${url}`, datas, {
            headers: authHeader()
        });
    }

    put(url, datas) {
        return axios.put(`${BASE_URL}${url}`, datas, {
            headers: authHeader()
        });
    }

    delete(url) {
        return axios.delete(`${BASE_URL}${url}`, {
            headers: authHeader()
        });
    }


}

export default new ApiService();