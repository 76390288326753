import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Spin,
  Select,
  notification,
  Button,
  PageHeader,
  Switch,
} from "antd";

import ApiService from "./../../utils/api";
import { useParams, useHistory } from "react-router-dom";
import PasswordGenerator from "./../../components/PasswordGenerator";

import { ASSETS_URL } from "./../../urlConfig";
import Avatar from "./../../components/Avatar";

var _ = require("lodash");

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const AddEditAccount = () => {
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [roles, setRoles] = useState([]);
  const [centers, setCenters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPasswordGenerator, setShowPasswordGenerator] = useState(false);
  const history = useHistory();
  let { id, action } = useParams();

  // const [fileList, setFileList] = useState([]);

  useEffect(() => {
    form.resetFields();
    async function fetchData() {
      const record = [];
      if (id) {
        const temp = await ApiService.get(`/users/${id}`);
        Object.keys(temp.data).forEach((key) => {
          if (key === "roles") {
            let idModified = temp.data[key].map((obj) => {
              return {
                ...obj,
                key: obj.id,
              };
            });
            record.push({
              name: [key],
              value: idModified,
            });
          } else if (key === "avatar") {

            if(temp.data[key] !== null && temp.data[key] !== ''){
              record.push({
                name: [key],
                value: [{ url: `${ASSETS_URL}/avatars/${temp.data[key]}` }],
              });
            }else{
              record.push({
                name: [key],
                value: [],
              });
            }

            


          } else {
            record.push({
              name: [key],
              value: temp.data[key],
            });
          }
        });
      }
      const permissions = await ApiService.get(`/roles`);
      await setRoles(permissions.rows);
      const centers = await ApiService.get(`/centers`);
      await setCenters(centers.rows);
      if (id) {
        await setFields(record);
      }
      setLoading(false);
    }
    if (action === "edit" || action === "detail" || action === "add") {
      setLoading(true);
      fetchData();
    }
  }, [action, id, form]);

  const onFinish = async (values) => {


    // console.log(values);
    // delete values['avatar'];
    // console.log(values);
    console.log(values["avatar"]);
    // if (values["avatar"].length > 0) {
      // values["avatar"] = null;
      
      // if(values["avatar"][0] && values["avatar"][0].url){
        // delete values['avatar'];
      // }else{
      //   values['avatar'] = values['avatar'][0].response.tempFilePath;
      // }
    // }
    

    let newRoles = values.roles.map((e) => {
      return {
        id: e.key,
      };
    });
    values["roles"] = newRoles;

    // console.log(values);
    if (!values["centerId"]) {
      values["centerId"] = null;
    }

    if (action === "edit") {
      values["id"] = parseInt(id);
      await ApiService.put(`/users/`, values);
      notification.success({ message: "Enregistrement modifié" });
      history.push(`/admin/users/accounts`);
    }
    if (action === "add") {
      await ApiService.post(`/users/`, values);
      notification.success({ message: "Enregistrement ajouté" });
      history.push(`/admin/users/accounts`);
    }
    console.log("Success:", values);
  };

  const onPasswordGenerator = (values) => {
    // form.setFieldsValue({
    //   password: _.values(values)
    //     .sort()
    //     .join("-"),
    // });
    console.log(form.getFieldValue('notes'))
    const currentNotes = form.getFieldValue('notes');
    const tempPassword = _.values(values).join("-")
    form.setFieldsValue({
      password: tempPassword,
      notes: tempPassword+ '\n' + currentNotes,
    });

    setShowPasswordGenerator(false);
  };

  return (
    <>
      <PageHeader
        style={{ padding: "10px 0" }}
        onBack={() => history.goBack()}
        title="Comptes"
        subTitle={
          action === "edit"
            ? "Modifier"
            : action === "detail"
            ? "Détail"
            : "Ajout"
        }
      />
      <Spin spinning={loading}>
        <Form
          {...layout}
          form={form}
          fields={fields}
          name="form"
          style={{ maxWidth: 800, margin: "0 auto" }}
          onFinish={onFinish}
        >
          <Form.Item
            name="name"
            label="Nom"
            rules={[
              {
                required: true,
                message: "Nom obligatoire",
              },
            ]}
          >
            <Input disabled={action === "detail"} />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: "Email obligatoire",
              },
            ]}
          >
            <Input disabled={action === "detail"} />
          </Form.Item>

          <Form.Item
            name="phone"
            label="Téléphone"
            rules={[
              {
                required: false
              },
            ]}
          >
            <Input disabled={action === "detail"} />
          </Form.Item>

          {action !== "detail" && <Form.Item
            name="password"
            label="Mot de passe"
            // rules={[
            //   {
            //     required: true,
            //     message: "Mot de passe obligatoire",
            //   },
            // ]}
            extra={
              <Button
                onClick={() => {
                  setShowPasswordGenerator(true);
                }}
                style={{
                  paddingLeft: 0,
                  paddingRight: 0,
                  position: "absolute",
                  right: 0,
                }}
                type="link"
              >
                Générateur de mot de passe "simple"
              </Button>
            }
          >
            <Input.Password visibilityToggle={false} disabled={action === "detail"} placeholder="Saisir un mot de passe pour remplacer l'actuel" />
          </Form.Item>}
          


          <Form.Item
            label="Roles"
            name="roles"
            rules={[{ required: true, message: "Minimum un role" }]}
          >
            <Select
              allowClear
              mode="multiple"
              labelInValue
              disabled={action === "detail"}
            >
              {roles.map((role, i) => (
                <Select.Option value={role.id} key={role.id}>
                  {role.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Centre" name="centerId">
            <Select allowClear disabled={action === "detail"}>
              {centers.map((center, i) => (
                <Select.Option value={center.id} key={center.id}>
                  {center.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="avatar"
            label="Avatar"
            valuePropName="fileList"
            getValueFromEvent={(e) => e && e.fileList}
            extra={action !== "detail" ? "format jpg/jpeg/png" : null}
          >
            <Avatar disabled={action === "detail"} />
          </Form.Item>

          <Form.Item name="active" label="Actif" valuePropName="checked">
            <Switch disabled={action === "detail"} />
          </Form.Item>

          <Form.Item
            name="notes"
            label="Notes"
          >
            <Input.TextArea rows={3} disabled={action === "detail"} />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
            {action === "detail" && (
              <Button type="default" onClick={() => history.goBack()}>
                Retour
              </Button>
            )}
            {action === "edit" && (
              <Button type="primary" htmlType="submit">
                Modifier
              </Button>
            )}
            {action === "add" && (
              <Button type="primary" htmlType="submit">
                Ajouter
              </Button>
            )}
          </Form.Item>
        </Form>
        <div>
          <PasswordGenerator
            visible={showPasswordGenerator}
            onCreate={onPasswordGenerator}
            onCancel={() => {
              setShowPasswordGenerator(false);
            }}
          />
        </div>
      </Spin>
    </>
  );
};
export default AddEditAccount;
