import React, { useEffect } from "react";
import { observer, inject } from "mobx-react";
import { Table, Button, Popconfirm, PageHeader } from "antd";
import frFR from "rc-pagination/lib/locale/fr_FR";

import useTableSearch from "../../components/useTableSearch";
import useTableDate from "../../components/useTableDate";

// import moment from "moment";

import { UserOutlined } from "@ant-design/icons";

import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";

const Sessions = ({ sessionStore }) => {
  useEffect(() => {
    sessionStore.getAll();
  }, [sessionStore]);

  const dataSource = sessionStore.records;
  const getColumnDateProps = useTableDate();
  const getColumnSearchProps = useTableSearch();

  const columns = [
    {
      title: "Sessions",
      dataIndex: "user",
      key: "user.email",
      ...getColumnSearchProps("Email"),
      sorter: true,
      sortOrder: sessionStore.sortField === "user" && sessionStore.sortOrder,
      render: (user) => {
        return (
          <span>
            {user.email} <UserOutlined />
          </span>
        );
      },
    },
    {
      title: "Date de création",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      sortOrder:
        sessionStore.sortField === "createdAt" && sessionStore.sortOrder,
      // render: (createdAt) => {
      //   return <>{moment(createdAt).format("DD-MM-YYYY HH:mm:ss")}</>;
      // },
      ...getColumnDateProps("Date de création"),
      defaultFilteredValue:
        sessionStore.filters && sessionStore.filters.createdAt,
    },
    {
      title: "Date d'expiration",
      dataIndex: "expireAt",
      key: "expireAt",
      sorter: true,
      sortOrder:
        sessionStore.sortField === "expireAt" && sessionStore.sortOrder,
      // render: (expireAt) => {
      //   return <>{moment(expireAt).format("DD-MM-YYYY HH:mm:ss")}</>;
      // },
      ...getColumnDateProps("Date d'expiration"),
      defaultFilteredValue:
        sessionStore.filters && sessionStore.filters.expireAt,
    },
    {
      title: "Actions",
      width: 150,
      key: "action",
      align: "left",
      render: (text, record) => (
        <>
          <Popconfirm
            title="Supprimer ?"
            okText="Oui"
            cancelText="Annuler"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={async () => {
              await sessionStore.deleteRecord(record.token);
              sessionStore.getAll();
            }}
          >
            <Button
              type="danger"
              shape="circle"
              icon={<DeleteOutlined />}
            ></Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    // sessionStore.setFilters(filters);
    // sessionStore.setPagination(pagination);
    // sessionStore.setSortField(sorter.field);
    // sessionStore.setSortOrder(sorter.order);
    // sessionStore.getAll();
    sessionStore.getAll({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters,
    });
  };

  return (
    <div>
      <PageHeader
        style={{ padding: "10px 0" }}
        title="Sessions"
        subTitle="Liste"
      />

      <Table
        onChange={handleTableChange}
        locale={{
          emptyText: "Aucuns enregistrements",
        }}
        pagination={{
          ...sessionStore.pagination,
          showTotal: (total, range) => `${range[0]}-${range[1]} / ${total}`,
          showPrevNextJumpers: false,
          locale: frFR,
        }}
        rowKey={(record) => record.token}
        loading={sessionStore.loading}
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );
};

export default inject("sessionStore")(observer(Sessions));
